import { useState } from 'react';
import { constants } from './../Constants';
import { Icon } from '@iconify/react';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DonationHistory = () => {
    document.title = "Donation History | Fan Website";

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className='general-card donation-history-card mb-4'>
                <div className='general-card-header'>
                    <h4 className="heading1 mb-0">Donation History</h4>
                </div>
                <Table responsive bordered hover className='mb-0'>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Poster</th>
                            <th>Donator</th>
                            <th>Hearts</th>
                        </tr>
                    </thead>
                    <tbody>
                        {constants.posters.slice(0, Math.max(2,Math.round(Math.random()*10))).map((item) => (
                            <tr key={ item.id }>
                                <td style={{ minWidth: '100px' }}>20-08-2022</td>
                                <td style={{ minWidth: '130px' }}>{ item.title }</td>
                                <td style={{ minWidth: '100px' }}>{ item.name }</td>
                                <td style={{ minWidth: '100px' }}>A cup of hearts x {Math.max(2,Math.round(Math.random()*10))}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}
 
export default DonationHistory;