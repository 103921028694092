import { useState } from 'react';
import { constants } from './Constants';
import ArtistCard from './ArtistCard';
import { Icon } from '@iconify/react';
import PosterCard from './PosterCard';
import { Button } from 'react-bootstrap';

const Search = () => {
    document.title = "Search | Fan Website";

    const [tab, setTab] = useState('artists')
    
    return (
        <div className="container-fluid mb-4 pt-2">
            <div className='text-center mb-4'>
                <Button variant={tab == 'artists' ? 'tab' : 'outline-tab'} onClick={() => setTab('artists')}>
                    <Icon icon="icon-park-solid:avatar" width={18} height={18} />Artists
                </Button>

                <Button variant={tab == 'posters' ? 'tab' : 'outline-tab'} onClick={() => setTab('posters')}>
                    <Icon icon="bi:images" width={18} height={18} />Posters
                </Button>
            </div>

            <div className={`row row-cols-card ${tab == 'artists' ? 'd-flex' : 'd-none'}`}>
                {constants.artists.map((item) => (
                    <ArtistCard artist={item} className={'col mb-4'} />
                ))}
            </div>

            <div className={`row row-cols-card ${tab == 'posters' ? 'd-flex' : 'd-none'}`}>
                {constants.posters.map((item) => (
                    <PosterCard poster={item} className={'col mb-4'} />
                ))}
            </div>
        </div>
    );
}
 
export default Search;