import { useState } from 'react';
import { constants } from './../Constants';
import { Icon } from '@iconify/react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    document.title = "Artist Dashboard | Fan Website";

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className='text-center mb-3'>
                <img src="https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_1.jpg" className='avatar' />

                <h6 className="title">
                    Lucian Obrien
                </h6>

                <p className="info-title mt-1 mb-3">
                    <span className='text-dark font-weight-bold'>91.56k</span> followers&nbsp;&nbsp;‒&nbsp;&nbsp;
                    <span className='text-dark font-weight-bold'>3.41k</span> posts
                </p>

                <div className="d-flex justify-content-center mt-1 mb-2">
                    <div className="px-4 border-right">
                        <p className="info-title">Total Hearts</p>
                        <p className="info-count">125</p>
                    </div>
                    <div className="px-4 border-right">
                        <p className="info-title">Remaining Hearts</p>
                        <p className="info-count">20</p>
                    </div>
                    <div className="px-4">
                        <p className="info-title">To be exchanged</p>
                        <p className="info-count">30</p>
                    </div>
                </div>

                <Button as={Link} to={'/artists/posters'} variant='tab' className="mt-1 mb-3">
                    <Icon icon="bi:images" width={20} height={20} />Manage Posters
                </Button>

                <div className='row justify-content-center'>
                    <div className='col-xl-8 col-lg-9 col-md-10'>
                        <p className="info px-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>

                <div className='mt-3'>
                    <Button as={Link} to="/artists/donation-history" className="mb-2 mx-1">
                        Donation History
                    </Button>
                    <Button as={Link} to="/artists/exchange-history" className="mb-2 mx-1">
                        Exchange History
                    </Button>
                    <Button as={Link} to="/artists/register-information" className="mb-2 mx-1">
                        Register Information for Exchange
                    </Button>
                    <Button as={Link} to="/artists/personal-information" className="mb-2 mx-1">
                        Manage Profile
                    </Button>
                </div>
            </div>
        </div>
    );
}
 
export default Dashboard;