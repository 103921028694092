import { useState } from 'react';
import { constants } from './../Constants';
import { Icon } from '@iconify/react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PosterCard from './PosterCard';
import DeletePopup from '../DeletePopup';

const PostersIndex = () => {
    document.title = "Manage Posters | Fan Website";

    const [items, setItems] = useState([]);
    const [deleteItem, setDeleteItem] = useState(null);

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            {deleteItem ? <DeletePopup setItems={setItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="heading1 mb-0">Manage Posters</h4>
                <Button as={Link} to={'/artists/posters/create'} variant='tab' size='sm' className='mx-0'>
                    Create a poster
                </Button>
            </div>

            <div className='row row-cols-card'>
                {constants.posters.map((item) => (
                    <PosterCard poster={item} setDeleteItem={setDeleteItem} />
                ))}
            </div>
        </div>
    );
}
 
export default PostersIndex;