import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Icon } from "@iconify/react";

const ArtistNavbar = ({sidebarToggled, setSidebarToggled, user, removeUser}) => {
    let navigate = useNavigate();

    const [searchActive, setSearchActive] = useState(false)
    const [query, setQuery] = useState('')

    const handleLogout = () => {
        fetch(process.env.REACT_APP_BASE_API_URL+'/logout', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })

        removeUser();
        navigate('/');
    }

    return ( 
        <nav className="navbar navbar-expand navbar-light topbar static-top shadow">
            <Link id="sidebarToggleTop" className="rounded-circle mr-2" onClick={() => setSidebarToggled(!sidebarToggled)}>
                <Icon icon="material-symbols:menu-rounded" width={20} height={20} />
            </Link>
            <Navbar.Brand>
                <Link to={"/"}>
                    <img src="https://minimals.cc/favicon/apple-touch-icon.png"/>
                </Link>
            </Navbar.Brand>
            <ul className="navbar-nav d-none d-sm-flex">
                <Nav.Link onClick={(e) => e.preventDefault()}>Artist Center</Nav.Link>
            </ul>
        </nav>
     );
}
 
export default ArtistNavbar;