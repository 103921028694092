import { useState } from 'react';
import { constants } from './Constants';
import ArtistCard from './ArtistCard';
import { Icon } from '@iconify/react';
import PosterCard from './PosterCard';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DonationHistory = () => {
    document.title = "Donation History | Fan Website";

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className='general-card donation-history-card mb-4'>
                <div className='general-card-header'>
                    <h4 className="heading1 mb-0">Donation History</h4>
                </div>
                {constants.posters.slice(0, Math.max(2,Math.round(Math.random()*10))).map((poster) => (
                    <div className='item'>
                        <img src={poster.images[0]} className="avatar" />
                        <div className="d-flex flex-column">
                            <p className="title">{poster.title}</p>
                            <p className="package">A cup of hearts x 2</p>
                            <p className="date">21 June 2022 10:30 PM</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
 
export default DonationHistory;