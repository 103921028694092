import { useRef, useState } from 'react';
import { constants } from './../Constants';
import { Icon } from '@iconify/react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

const ProfileInfo = () => {
    document.title = "Personal Information | Fan Website";
    
    const fileRef = useRef();

    const [name, setName] = useState('');
    const [introduction, setIntroduction] = useState('');
    
    const [file, setFile] = useState(null);
    
    const handleImageChange = (e) => {
        let formData = new FormData();

        var file = e.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setFile(reader.result)
        }.bind(this);

        if (fileRef?.current)
        {
            fileRef.current.value = '';
        }
    }

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className="row justify-content-center flex-fill">
                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10">
                    <div className='general-card mb-4'>
                        <div className='general-card-header'>
                            <h4 className="heading1 mb-0">Personal Information</h4>
                        </div>
                        <div className="px-4 py-4">
                            <form className="user">
                                <label className="custom-file-container custom-file-container-profile">
                                    <input 
                                        type="file" 
                                        ref={fileRef}
                                        onChange={handleImageChange}
                                        className="custom-file-container-input" 
                                        accept="image/*" 
                                        multiple="multiple"/>
                                    
                                    <span className={`custom-file-container-control d-flex justify-content-center align-items-center`}>
                                        <img src={file ?? 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_1.jpg'} />

                                        <span className='overlay'>
                                            <Icon icon='ic:round-add-a-photo' height={22} width={22} />
                                            <span>Update Photo</span>
                                        </span>
                                    </span>
                                </label>
                                
                                <TextField 
                                    variant="outlined" 
                                    label="Artist name" 
                                    value={name}
                                    onChange={ (e) => setName(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <TextField 
                                    variant="outlined" 
                                    label="Introduction" 
                                    value={introduction}
                                    onChange={ (e) => setIntroduction(e.target.value) }
                                    multiline={true}
                                    rows={3}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <button 
                                    type="submit" 
                                    className="btn btn-secondary btn-user btn-block"
                                    >Update Information</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ProfileInfo;