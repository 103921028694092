export const constants = {
    artists: [
        {
            "id": 1,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_1.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_1.jpg",
            "name": "Jayvion Simon",
            "follower": 91775,
            "following": 96857,
            "totalPost": 65514,
            "position": "ux designer"
        },
        {
            "id": 2,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_2.jpg",
            "name": "Lucian Obrien",
            "follower": 22946,
            "following": 69598,
            "totalPost": 82370,
            "position": "full stack designer"
        },
        {
            "id": 3,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_3.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_3.jpg",
            "name": "Deja Brady",
            "follower": 31876,
            "following": 41465,
            "totalPost": 15688,
            "position": "backend developer"
        },
        {
            "id": 4,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_4.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_4.jpg",
            "name": "Harrison Stein",
            "follower": 83468,
            "following": 18558,
            "totalPost": 78111,
            "position": "ux designer"
        },
        {
            "id": 5,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_5.jpg",
            "name": "Reece Chung",
            "follower": 45900,
            "following": 76653,
            "totalPost": 81393,
            "position": "ux designer"
        },
        {
            "id": 6,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_6.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_6.jpg",
            "name": "Lainey Davidson",
            "follower": 63471,
            "following": 88148,
            "totalPost": 45124,
            "position": "project manager"
        },
        {
            "id": 7,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_7.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_7.jpg",
            "name": "Cristopher Cardenas",
            "follower": 52373,
            "following": 13247,
            "totalPost": 43422,
            "position": "leader"
        },
        {
            "id": 8,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_8.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_8.jpg",
            "name": "Melanie Noble",
            "follower": 96401,
            "following": 60097,
            "totalPost": 42965,
            "position": "backend developer"
        },
        {
            "id": 9,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_9.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_9.jpg",
            "name": "Chase Day",
            "follower": 27710,
            "following": 20225,
            "totalPost": 61893,
            "position": "project manager"
        },
        {
            "id": 10,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_10.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_10.jpg",
            "name": "Shawn Manning",
            "follower": 4520,
            "following": 80020,
            "totalPost": 45100,
            "position": "ui designer"
        },
        {
            "id": 11,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_11.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_11.jpg",
            "name": "Soren Durham",
            "follower": 2837,
            "following": 8455,
            "totalPost": 29285,
            "position": "ui/ux designer"
        },
        {
            "id": 12,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_12.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_12.jpg",
            "name": "Cortez Herring",
            "follower": 53420,
            "following": 88071,
            "totalPost": 64821,
            "position": "ui/ux designer"
        },
        {
            "id": 13,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_13.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_13.jpg",
            "name": "Brycen Jimenez",
            "follower": 72988,
            "following": 18432,
            "totalPost": 34356,
            "position": "ui designer"
        },
        {
            "id": 14,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_14.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_14.jpg",
            "name": "Giana Brandt",
            "follower": 81018,
            "following": 14821,
            "totalPost": 39059,
            "position": "backend developer"
        },
        {
            "id": 15,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_15.jpg",
            "name": "Aspen Schmitt",
            "follower": 39928,
            "following": 42162,
            "totalPost": 93295,
            "position": "backend developer"
        },
        {
            "id": 16,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_16.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_16.jpg",
            "name": "Colten Aguilar",
            "follower": 88731,
            "following": 26633,
            "totalPost": 42861,
            "position": "front end developer"
        },
        {
            "id": 17,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_17.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_17.jpg",
            "name": "Angelique Morse",
            "follower": 7047,
            "following": 32905,
            "totalPost": 6222,
            "position": "backend developer"
        },
        {
            "id": 18,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_18.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_18.jpg",
            "name": "Selina Boyer",
            "follower": 63125,
            "following": 85774,
            "totalPost": 28095,
            "position": "full stack designer"
        },
        {
            "id": 19,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_19.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_19.jpg",
            "name": "Lawson Bass",
            "follower": 29347,
            "following": 42744,
            "totalPost": 90214,
            "position": "full stack developer"
        },
        {
            "id": 20,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_20.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_20.jpg",
            "name": "Ariana Lang",
            "follower": 2815,
            "following": 24960,
            "totalPost": 93836,
            "position": "backend developer"
        },
        {
            "id": 21,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_21.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_21.jpg",
            "name": "Amiah Pruitt",
            "follower": 29629,
            "following": 99451,
            "totalPost": 62860,
            "position": "ux designer"
        },
        {
            "id": 22,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_22.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_22.jpg",
            "name": "Harold Mcgrath",
            "follower": 46640,
            "following": 64880,
            "totalPost": 10008,
            "position": "ui designer"
        },
        {
            "id": 23,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_23.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_23.jpg",
            "name": "Esperanza Mcintyre",
            "follower": 19989,
            "following": 33261,
            "totalPost": 81481,
            "position": "project manager"
        },
        {
            "id": 24,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_24.jpg",
            "cover": "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_24.jpg",
            "name": "Mireya Conner",
            "follower": 94212,
            "following": 26350,
            "totalPost": 69742,
            "position": "ui/ux designer"
        }
    ],
    posters: [
        {
            "id": 1,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_1.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_1.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_2.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_2.jpg"
            ],
            "title": "Apply These 7 Secret Techniques To Improve Event",
            "name": "Jayvion Simon",
            "location": "Venue Hall",
            "createdAt": "2023-01-14T19:36:49.236Z"
        },
        {
            "id": 2,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_2.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_3.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_3.jpg"
            ],
            "title": "Believing These 7 Myths About Event Keeps You From Growing",
            "name": "Lucian Obrien",
            "location": "Venue Hall",
            "createdAt": "2023-01-13T18:36:49.236Z"
        },
        {
            "id": 3,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_3.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_3.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_4.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_4.jpg"
            ],
            "title": "Don't Waste Time! 7 Facts Until You Reach Your Event",
            "name": "Deja Brady",
            "location": "Venue Hall",
            "createdAt": "2023-01-12T17:36:49.236Z"
        },
        {
            "id": 4,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_4.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_4.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_5.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_5.jpg"
            ],
            "title": "How 7 Things Will Change The Way You Approach Event",
            "name": "Harrison Stein",
            "location": "Venue Hall",
            "createdAt": "2023-01-11T16:36:49.236Z"
        },
        {
            "id": 5,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_5.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_6.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_6.jpg"
            ],
            "title": "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
            "name": "Reece Chung",
            "location": "Venue Hall",
            "createdAt": "2023-01-10T15:36:49.236Z"
        },
        {
            "id": 6,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_6.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_6.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_7.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_7.jpg"
            ],
            "title": "Event Doesn't Have To Be Hard. Read These 7 Tips",
            "name": "Lainey Davidson",
            "location": "Venue Hall",
            "createdAt": "2023-01-09T14:36:49.236Z"
        },
        {
            "id": 7,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_7.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_7.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_8.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_8.jpg"
            ],
            "title": "Event Is Your Worst Enemy. 7 Ways To Defeat It",
            "name": "Cristopher Cardenas",
            "location": "Venue Hall",
            "createdAt": "2023-01-08T13:36:49.236Z"
        },
        {
            "id": 8,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_8.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_8.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_9.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_9.jpg"
            ],
            "title": "Event On A Budget: 7 Tips From The Great Depression",
            "name": "Melanie Noble",
            "location": "Venue Hall",
            "createdAt": "2023-01-07T12:36:49.236Z"
        },
        {
            "id": 9,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_9.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_9.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_10.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_10.jpg"
            ],
            "title": "Knowing These 7 Secrets Will Make Your Event Look Amazing",
            "name": "Chase Day",
            "location": "Venue Hall",
            "createdAt": "2023-01-06T11:36:49.237Z"
        },
        {
            "id": 10,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_10.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_10.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_11.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_11.jpg"
            ],
            "title": "Master The Art Of Event With These 7 Tips",
            "name": "Shawn Manning",
            "location": "Venue Hall",
            "createdAt": "2023-01-05T10:36:49.237Z"
        },
        {
            "id": 11,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_11.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_11.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_12.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_12.jpg"
            ],
            "title": "My Life, My Job, My Career: How 7 Simple Event Helped Me Succeed",
            "name": "Soren Durham",
            "location": "Venue Hall",
            "createdAt": "2023-01-04T09:36:49.237Z"
        },
        {
            "id": 12,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_12.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_12.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_13.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_13.jpg"
            ],
            "title": "Take Advantage Of Event - Read These 7 Tips",
            "name": "Cortez Herring",
            "location": "Venue Hall",
            "createdAt": "2023-01-03T08:36:49.237Z"
        },
        {
            "id": 13,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_13.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_13.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_14.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_14.jpg"
            ],
            "title": "The Next 7 Things You Should Do For Event Success",
            "name": "Brycen Jimenez",
            "location": "Venue Hall",
            "createdAt": "2023-01-02T07:36:49.237Z"
        },
        {
            "id": 14,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_14.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_14.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_15.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_15.jpg"
            ],
            "title": "The Time Is Running Out! Think About These 7 Ways To Change Your Event",
            "name": "Giana Brandt",
            "location": "Venue Hall",
            "createdAt": "2023-01-01T06:36:49.237Z"
        },
        {
            "id": 15,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_15.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_15.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_16.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_16.jpg"
            ],
            "title": "The 7 Best Things About Event",
            "name": "Aspen Schmitt",
            "location": "Venue Hall",
            "createdAt": "2022-12-31T05:36:49.237Z"
        },
        {
            "id": 16,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_16.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_16.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_17.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_17.jpg"
            ],
            "title": "The 7 Biggest Event Mistakes You Can Easily Avoid",
            "name": "Colten Aguilar",
            "location": "Venue Hall",
            "createdAt": "2022-12-30T04:36:49.237Z"
        },
        {
            "id": 17,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_17.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_17.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_18.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_18.jpg"
            ],
            "title": "The 7 Most Successful Event Companies In Region",
            "name": "Angelique Morse",
            "location": "Venue Hall",
            "createdAt": "2022-12-29T03:36:49.237Z"
        },
        {
            "id": 18,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_18.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_18.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_19.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_19.jpg"
            ],
            "title": "Think Your Event Is Safe? 7 Ways You Can Lose It Today",
            "name": "Selina Boyer",
            "location": "Venue Hall",
            "createdAt": "2022-12-28T02:36:49.237Z"
        },
        {
            "id": 19,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_19.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_19.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_20.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_20.jpg"
            ],
            "title": "Thinking About Event? 7 Reasons Why It's Time To Stop!",
            "name": "Lawson Bass",
            "location": "Venue Hall",
            "createdAt": "2022-12-27T01:36:49.237Z"
        },
        {
            "id": 20,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_20.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_20.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_21.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_21.jpg"
            ],
            "title": "7 Places To Get Deals On Event",
            "name": "Ariana Lang",
            "location": "Venue Hall",
            "createdAt": "2022-12-26T00:36:49.237Z"
        },
        {
            "id": 21,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_21.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_21.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_22.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_22.jpg"
            ],
            "title": "Best Event Android Apps",
            "name": "Amiah Pruitt",
            "location": "Venue Hall",
            "createdAt": "2022-12-24T23:36:49.237Z"
        },
        {
            "id": 22,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_22.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_22.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_23.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_23.jpg"
            ],
            "title": "Best Event Tips You Will Read This Year",
            "name": "Harold Mcgrath",
            "location": "Venue Hall",
            "createdAt": "2022-12-23T22:36:49.237Z"
        },
        {
            "id": 23,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_23.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_23.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_24.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_24.jpg"
            ],
            "title": "Best 30 Tips For Event",
            "name": "Esperanza Mcintyre",
            "location": "Venue Hall",
            "createdAt": "2022-12-22T21:36:49.237Z"
        },
        {
            "id": 24,
            "avatarUrl": "https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_24.jpg",
            "images": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_24.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_25.jpg",
                "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_25.jpg"
            ],
            "title": "Should Fixing Event Take 30 Steps?",
            "name": "Mireya Conner",
            "location": "Venue Hall",
            "createdAt": "2022-12-21T20:36:49.237Z"
        }
    ]
}