import React from 'react';
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom';
import AdminApp from './Admin/Components/App';
import ScrollToTop from './Admin/Components/ScrollToTop';
import './fontawesome';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <AdminApp />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
