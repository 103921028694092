import { useState } from 'react';
import { constants } from './Constants';
import ArtistCard from './ArtistCard';
import { Icon } from '@iconify/react';
import PosterCard from './PosterCard';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

const ProfileInfo = () => {
    document.title = "Personal Information | Fan Website";
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [changePassword, setChangePassword] = useState(false)
    const [password, setPassword] = useState('');

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className="row justify-content-center flex-fill">
                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10">
                    <div className='general-card mb-4'>
                        <div className='general-card-header'>
                            <h4 className="heading1 mb-0">Personal Information</h4>
                        </div>
                        <div className="px-4 py-4">
                            <form className="user">
                                <TextField 
                                    variant="outlined" 
                                    label="Full name" 
                                    value={name}
                                    onChange={ (e) => setName(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <TextField 
                                    variant="outlined" 
                                    label="Email address" 
                                    value={email}
                                    onChange={ (e) => setEmail(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <FormControlLabel control={<Checkbox onChange={() => setChangePassword(!changePassword)} />} label="Change Password" />
                                
                                <div className={`${changePassword ? 'd-block' : 'd-none'}`}>
                                    <TextField 
                                        variant="outlined" 
                                        type={"password"}
                                        label="Password" 
                                        value={password}
                                        onChange={ (e) => setPassword(e.target.value) }
                                        required={true}
                                        style={{ width: '100%' }}
                                        className="mb-3"
                                        />
                                </div>

                                <button 
                                    type="submit" 
                                    className="btn btn-secondary btn-user btn-block"
                                    >Update Information</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ProfileInfo;