import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ArtistCard = ({artist, className = 'px-2 pb-3'}) => {
    return (
        <div className={className}>
            <div className='artist-card'>
                <div className="position-relative">
                    <span className="avatar-shape"></span>
                    <Link to={'/artist-details'}>
                        <div className="avatar">
                            <img src={artist.avatarUrl} />
                        </div>
                    </Link>
                    <div className="overlay"></div>
                    <span className="poster">
                        <span>
                            <img src={artist.cover} />
                        </span>
                    </span>
                </div>
                <Link to={'/artist-details'}>
                    <h6 className="title">
                        {artist.name}
                    </h6>
                    <p className="info">
                        {artist.position}
                    </p>
                </Link>
                <hr className="divider" />
                <div className="d-flex justify-content-between py-2">
                    <div className="flex-fill">
                        <p className="info-title">Followers</p>
                        <p className="info-count">91.56k</p>
                    </div>
                    <div className="flex-fill">
                        <p className="info-title">Posts</p>
                        <p className="info-count">3.41k</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArtistCard;