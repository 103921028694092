import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PosterCard = ({poster, className = 'px-2 pb-3'}) => {
    return (
        <div className={className}>
            <div className='poster-card'>
                <div className="poster">
                    <Link to={'/poster-details'}>
                        <span>
                            <img src={poster.images[0]} />
                        </span>
                    </Link>
                </div>
                <div className="px-3">
                    <Link to={'/poster-details'}>
                        <h6 className="title">
                            {poster.name}
                        </h6>
                        <p className="info">
                            12 Jan 2022 | Venue Hall
                        </p>
                    </Link>
                    <Link to={'/artist-details'}>
                        <div className="d-flex align-items-center mb-2 pb-1">
                            <img src={poster.avatarUrl} className="avatar" />
                            <div className="flex-fill">
                                <p className="artist-title">{poster.name}</p>
                                <p className="artist-info">3.41k followers</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default PosterCard;