import { useRef, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DonePages = () => {
    document.title = "Done pages | Fan Website";
    
    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <Button as={Link} to="/login" variant='info' className='mb-2 mr-2'>Login</Button>
            <Button as={Link} to="/register" variant='info' className='mb-2 mr-2'>Register</Button>
            <br />
            <Button as={Link} to="/" className='mb-2 mr-2'>Home</Button>
            <Button as={Link} to="/search" className='mb-2 mr-2'>Search</Button>
            <Button as={Link} to="/artist-details" className='mb-2 mr-2'>Artist Detail</Button>
            <Button as={Link} to="/poster-details" className='mb-2 mr-2'>Poster Detail</Button>
            <Button as={Link} to="/favorite-artists" className='mb-2 mr-2'>Favorite Artists</Button>
            <Button as={Link} to="/personal-information" className='mb-2 mr-2'>Profile Info</Button>
            <br />
            <Button as={Link} to="/artists/dashboard" variant='success' className='mb-2 mr-2'>Artist Dashboard</Button>
            <Button as={Link} to="/artists/posters" variant='success' className='mb-2 mr-2'>Artist Posters Index</Button>
            <Button as={Link} to="/artists/posters/create" variant='success' className='mb-2 mr-2'>Artist Posters Create</Button>
        </div>
    );
}
 
export default DonePages;