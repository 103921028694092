import { useRef, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const PostersCreate = () => {
    document.title = "Create a poster | Fan Website";
    
    const fileRef = useRef();

    const maxFiles = 3;

    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [location, setLocation] = useState('')
    const [fanNotes, setFanNotes] = useState('')
    const [description, setDescription] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState(0);
    
    const handleImageChange = (e) => {
        let formData = new FormData();

        var remainingFiles = maxFiles - uploadedFiles.length - uploadingFiles;
        remainingFiles = Math.min(e.target.files.length, remainingFiles);
        for(var i=0; i<remainingFiles; i++)
        {
            formData.append("images[]", e.target.files[i]);
        }
        setUploadingFiles(current => current + remainingFiles);
        
        for(var i=0; i<remainingFiles; i++)
        {
            var file = e.target.files[i];
            var reader = new FileReader();
            var url = reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                setUploadingFiles(current => current > 1 ? current - 1 : 0);
                setUploadedFiles(values => ([...values, {name: file.name, full_path: reader.result}]));
            }.bind(this);
        }

        if (fileRef?.current)
        {
            fileRef.current.value = '';
        }
    }

    const handleDeleteImage = (name) => {
        setUploadedFiles(uploadedFiles.filter((file) => {
            return file.name != name;
        }));
    }
    
    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className="row justify-content-center flex-fill">
                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10">
                    <div className='general-card mb-4'>
                        <div className='general-card-header d-flex justify-content-between'>
                            <h4 className="heading1 mb-0">Create a poster</h4>
                            <Link to={'/artists/posters'} className="go-back mb-0">Go Back</Link>
                        </div>
                        <div className="px-4 py-4">
                            <form className="user">
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <div className='mr-2'>
                                        <label className='mb-1'>Upload Pictures</label>
                                        <p className="mb-0 small">Upload upto {maxFiles} images</p>
                                    </div>
                                    <div>
                                        <label className="custom-file-container">
                                            <input 
                                                type="file" 
                                                ref={fileRef}
                                                onChange={handleImageChange}
                                                disabled={uploadedFiles.length+uploadingFiles>=maxFiles}
                                                className="custom-file-container-input" 
                                                accept="image/*" 
                                                multiple="multiple"/>
                                            
                                            <span className={`custom-file-container-control d-flex justify-content-center align-items-center ${uploadedFiles.length+uploadingFiles>=maxFiles ? 'disabled' : ''}`}>
                                                <Icon icon={'ph:plus-bold'} height={16} width={16} className='d-none d-sm-inline' />
                                                <span className="text-center ml-sm-1">Add Photos</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                
                                { uploadedFiles.length > 0 || uploadingFiles > 0 ? (
                                    <div className="row mt-n2 no-gutters mx-n2 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 file-previews image-previews">
                                        { uploadedFiles.map((uploadedFile) => (
                                            <div key={uploadedFile.name} className="col mb-3 px-2 custom-file-container-preview position-relative">
                                                <div className="custom-file-container-preview-item">
                                                    <span onClick={() => handleDeleteImage(uploadedFile.name)} className="custom-file-container-preview-remove d-flex justify-content-center align-items-center">
                                                        <Icon icon={'ph:x-bold'} />
                                                    </span>
                                                    <img src={uploadedFile.full_path} className="img-fluid"/>
                                                </div>
                                            </div>
                                        ))}
                                        
                                        { [...Array(uploadingFiles)].map((uploadingFile, index) => (
                                            <div key={index} className="col mb-3 px-2 custom-file-container-preview custom-file-container-preview-dummy position-relative">
                                                <div className="custom-file-container-preview-item custom-file-container-preview-nonimage" style={{background: '#0E9877'}}>
                                                    <p>Uploading</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : null }

                                <TextField 
                                    variant="outlined" 
                                    label="Poster title" 
                                    value={title}
                                    onChange={ (e) => setTitle(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <TextField 
                                    variant="outlined" 
                                    label="Poster date" 
                                    value={date}
                                    onChange={ (e) => setDate(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <TextField 
                                    variant="outlined" 
                                    label="Location" 
                                    value={location}
                                    onChange={ (e) => setLocation(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />
                                    
                                <TextField 
                                    variant="outlined" 
                                    label="A word to the fans" 
                                    value={fanNotes}
                                    onChange={ (e) => setFanNotes(e.target.value) }
                                    required={true}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />
                                    
                                <TextField 
                                    variant="outlined" 
                                    label="Description" 
                                    value={description}
                                    onChange={ (e) => setDescription(e.target.value) }
                                    required={true}
                                    multiline={true}
                                    rows={3}
                                    style={{ width: '100%' }}
                                    className="mb-3"
                                    />

                                <button 
                                    type="submit" 
                                    className="btn btn-secondary btn-user btn-block"
                                    >Create Poster</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PostersCreate;