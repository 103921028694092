import { useRef, useState } from 'react';
import { constants } from './Constants';
import ArtistCard from './ArtistCard';
import { Icon } from '@iconify/react';
import PosterCard from './PosterCard';
import { Button, Card, Image } from 'react-bootstrap';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

const images = [
    "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_1.jpg",
    "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_2.jpg",
    "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_3.jpg"
]
  
const PosterDetail = () => {
    document.title = "Poster Detail | Fan Website";

    const slider1 = useRef(null);

    const [openLightbox, setOpenLightbox] = useState(false);
    const [selectedImage, setSelectedImage] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [tab, setTab] = useState(0);
    
    const settings = {
        speed: 320,
        dots: false,
        arrows: false,
        slidesToShow: 1,
        draggable: false,
        slidesToScroll: 1,
        adaptiveHeight: true,
        beforeChange: (current, next) => setCurrentIndex(next),
    };

    const handleOpenLightbox = (image) => {
        setOpenLightbox(true);
        setSelectedImage(image);
    };

    const handlePrevious = () => {
        slider1.current?.slickPrev();
    };
  
    const handleNext = () => {
        slider1.current?.slickNext();
    };
    
    return (
        <>
            <div className="container-fluid poster-details mb-fixed-footer mb-md-4 pt-2">
                <div className='details-card'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div style={{ position: 'relative' }}>
                                <Slider {...settings} ref={slider1}>
                                    { images.map(value => 
                                        <Image
                                            key={value}
                                            src={value}
                                            onClick={() => handleOpenLightbox(value)}
                                            className="images"
                                        />
                                    ) }
                                </Slider>
                                
                                <div className='slider-btn'
                                    onNext={handleNext}
                                    onPrevious={handlePrevious}
                                >
                                    <span><Icon icon='eva:arrow-left-fill' onClick={handlePrevious} /></span>
                                    <p className="mb-0">
                                        {currentIndex + 1}/{images.length}
                                    </p>
                                    <span><Icon icon='eva:arrow-right-fill' onClick={handleNext} /></span>
                                </div>
                            </div>
                        </div>
                    
                        <div className='col-md-6'>
                            <h6 className="title">
                                Poster title: Test text here
                            </h6>
                            <p className="info"><span><Icon icon="bi:calendar-week" width={16} height={16} /></span><b>Date:</b> 12 January 2022</p>
                            <p className="info"><span><Icon icon="fontisto:map-marker-alt" width={16} height={16} /></span><b>Location:</b> Venue Hall</p>
                            <p className="info"><span><Icon icon="uim:star" width={16} height={16} /></span><b>Words to fans:</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                            {/* <p className="info d-block d-md-none d-lg-block"><span><Icon icon="bi:info-circle" width={16} height={16} /></span><b>Description:</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p> */}
                            
                            <Link to={'/artist-details'} style={{ textDecoration: 'none' }}>
                                <div className="d-flex align-items-center mt-3 mb-2 mb-md-3">
                                    <img src={'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg'} className="avatar" />
                                    <div className="flex-fill">
                                        <p className="artist-title">Lucian Obrien</p>
                                        <p className="artist-info">3.41k followers</p>
                                    </div>
                                </div>
                            </Link>

                            <Button as={Link} to="/donate" variant='tab' className="mx-0 mt-1 d-none d-md-inline-block">
                                <Icon icon="mdi:cards-heart" width={20} height={20} />Donate to the poster
                            </Button>
                        </div>
                    </div>

                    {/* <p className="info d-none d-md-block d-lg-none mt-3"><span><Icon icon="bi:info-circle" width={16} height={16} /></span><b>Description:</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p> */}
                </div>

                <div className='info-card p-0 mb-4 mt-4' style={{ border: '1px solid #dbe2ef' }}>
                    <Tabs value={tab} onChange={(e, value) => setTab(value)} className="px-3" variant="scrollable" scrollButtons={false}>
                        <Tab label="Description" />
                        <Tab label="Guestbook" />
                        <Tab label="Donations" />
                    </Tabs>
                    
                    <div>
                        <div className={`p-3 ${tab == 0 ? 'd-block' : 'd-none'}`}>
                            <p className="info mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                        </div>
                        <div className={`${tab == 1 ? 'd-block' : 'd-none'}`}>
                            <div className='chat-item'>
                                <img src={'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg'} className="avatar" />
                                <div>
                                    <p className="title">Lucian Obrien</p>
                                    <p className="info">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                </div>
                            </div>
                            <div className='chat-item'>
                                <img src={'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_3.jpg'} className="avatar" />
                                <div>
                                    <p className="title">Lucian Obrien</p>
                                    <p className="info">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                            </div>
                            <div className='chat-item'>
                                <img src={'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_4.jpg'} className="avatar" />
                                <div>
                                    <p className="title">Lucian Obrien</p>
                                    <p className="info">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                            <div className='chat-item'>
                                <img src={'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg'} className="avatar" />
                                <div>
                                    <p className="title">Lucian Obrien</p>
                                    <p className="info">I have donated 3 cup of hearts</p>
                                </div>
                            </div>
                            <div className="comment-input-box">
                                <input 
                                    placeholder="Type a message" 
                                    type="text" />
                                <button className="btn-send">
                                    <Icon icon="ic:round-send" width={22} height={22} />
                                    <span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
                                </button>
                            </div>
                        </div>
                        <div className={`${tab == 2 ? 'd-block' : 'd-none'}`}>
                            <div className='donation-item'>
                                <img src={'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg'} className="avatar" />
                                <p className="title">To show your name here<span className="info">| Make a donation</span></p>
                            </div>
                            <div className='donation-item'>
                                <img src={'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_3.jpg'} className="avatar" />
                                <p className="title">Lucian Obrien<span className="info">| A cup of hearts * 3</span></p>
                            </div>
                            <div className='donation-item'>
                                <img src={'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_4.jpg'} className="avatar" />
                                <p className="title">Lucian Obrien<span className="info">| A cup of hearts * 3</span></p>
                            </div>
                            <div className='donation-item'>
                                <img src={'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_4.jpg'} className="avatar" />
                                <p className="title">Lucian Obrien<span className="info">| A cup of hearts * 3</span></p>
                            </div>
                            <div className='donation-item'>
                                <img src={'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg'} className="avatar" />
                                <p className="title">Lucian Obrien<span className="info">| A cup of hearts * 3</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='fixed-footer d-md-none'>
                <Button as={Link} to="/donate" variant='tab' className="mx-0 flex-fill">
                    <Icon icon="mdi:cards-heart" width={20} height={20} />Donate to the poster
                </Button>
            </div>
        </>
    );
}
 
export default PosterDetail;