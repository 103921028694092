import { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import useFetch from '../../useFetch';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Link, useNavigate } from 'react-router-dom';
import useUser from '../../useUser';
import { constants } from './Constants';
import ArtistCard from './ArtistCard';
import { Icon } from '@iconify/react';
import PosterCard from './PosterCard';

const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 1050,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

const Dashboard = () => {
    document.title = "Home | Fan Website";

    const carouselArtistsRef = useRef(null);
    const carouselPostersRef = useRef(null);

    const handlePreviousArtists = () => { carouselArtistsRef.current?.slickPrev(); };
    const handleNextArtists = () => { carouselArtistsRef.current?.slickNext(); };

    const handlePreviousPoster = () => { carouselPostersRef.current?.slickPrev(); };
    const handleNextPoster = () => { carouselPostersRef.current?.slickNext(); };
    
    return (
        <div className="container-fluid mb-4">
            <img alt="country" src="https://docs.minimals.cc/static/images/hero.jpg" className='main-banner img-fluid' />

            <div className='mt-5'>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <h4 className="heading1 mb-0">Top 5 Artists</h4>
                    <div>
                        <Link onClick={handlePreviousArtists} className="carousel-btn">
                            <Icon icon="ic:round-keyboard-arrow-left" width={20} height={20} />
                        </Link>
                        <Link onClick={handleNextArtists} className="carousel-btn">
                            <Icon icon="ic:round-keyboard-arrow-right" width={20} height={20} />
                        </Link>
                    </div>
                </div>
                
                <div className='mx-n2'>
                    <Slider ref={carouselArtistsRef} {...settings}>
                        {constants.artists.map((item) => (
                            <ArtistCard artist={item} />
                        ))}
                    </Slider>
                </div>
            </div>

            <div className='mt-4 pt-2 mb-3'>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <h4 className="heading1 mb-0">Top 5 Posters</h4>
                    <div>
                        <Link onClick={handlePreviousPoster} className="carousel-btn">
                            <Icon icon="ic:round-keyboard-arrow-left" width={20} height={20} />
                        </Link>
                        <Link onClick={handleNextPoster} className="carousel-btn">
                            <Icon icon="ic:round-keyboard-arrow-right" width={20} height={20} />
                        </Link>
                    </div>
                </div>
                
                <div className='mx-n2'>
                    <Slider ref={carouselPostersRef} {...settings}>
                        {constants.posters.map((item) => (
                            <PosterCard poster={item} />
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}
 
export default Dashboard;