import { useEffect, useState } from "react";

const DeletePopup = ({setItems, deleteItem, setDeleteItem}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const handleDelete = () => {
        setIsLoading(true);
        setSuccess(null);
        setError(null);

        fetch(process.env.REACT_APP_BASE_API_URL+'/'+deleteItem.url, {
            method: 'DELETE',
            headers: { "Authorization": "Bearer "+localStorage.getItem("token") }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while deleting, please try again.');
            }

            return response.json();
        })
        .then(data => {
            if (data.status == 'success')
            {
                setSuccess(data.message);
                setItems(current => current.filter(value => value.id != deleteItem.id));
            }
            else
            {
                setError(data.message);
            }
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    return ( 
        <div className="card shadow mb-4" style={{
            position: 'fixed',
            height: 'calc(100vh + 10px)',
            width: 'calc(100vw + 10px)',
            top: '-10px',
            left: '-10px',
            background: '#000000dd',
            display: 'flex',
            zIndex: 1031,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <img src={success ? '/tick.svg' : error ? '/cross.svg' : '/confirm.svg'} style={{width: '70px'}} />

            <h4 className="h4 mb-1 mt-2 text-white">
                {isLoading ? 'Please wait...' : success ? 'Success' : error ? 'Error' : 'Confirm Action?'}
            </h4>
            <p className="p mb-3 text-white">
                {isLoading ? 'Please wait while record is deleting' : success ?? error ?? 'Are you sure you want to delete this?'}
            </p>
            {isLoading ? <div style={{height: 38}}></div> : 
                success ? <button onClick={() => setDeleteItem(null)} className="btn btn-success btn-submit w-100px mx-2">OK</button> : 
                <div>
                    <button onClick={() => setDeleteItem(null)} className="btn btn-light btn-submit w-100px mx-2">{error ? 'Cancel' : 'No'}</button>
                    <button onClick={handleDelete} className="btn btn-danger btn-submit w-100px mx-2">{error ? 'Try Again' : 'Yes'}</button>
                </div>
            }
        </div>
    );
}
 
export default DeletePopup;