import { useEffect } from "react";
import { useState } from "react";

export default function useUser() {
    const getUser = () => {
        return JSON.parse(localStorage.getItem("user"));
    };

    const [user, setUser] = useState(getUser());

    const saveUser = (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("username", data.user.name);
        setUser(data);
    };

    const removeUser = (user) => {
        localStorage.removeItem("user")
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        setUser(null);
    };

    return {
        setUser: saveUser,
        removeUser,
        user,
    };
}
