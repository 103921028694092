import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Icon } from "@iconify/react";

const FanNavbar = ({sidebarToggled, setSidebarToggled, user, removeUser}) => {
    let navigate = useNavigate();

    const [searchActive, setSearchActive] = useState(false)
    const [query, setQuery] = useState('')

    const handleLogout = () => {
        fetch(process.env.REACT_APP_BASE_API_URL+'/logout', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })

        removeUser();
        navigate('/');
    }

    return ( 
        <nav className="navbar navbar-expand navbar-light topbar static-top shadow">
            <Link id="sidebarToggleTop" className="rounded-circle mr-2" onClick={() => setSidebarToggled(!sidebarToggled)}>
                <Icon icon="material-symbols:menu-rounded" width={20} height={20} />
            </Link>
            <Navbar.Brand>
                <Link to={"/"}>
                    <img src="https://minimals.cc/favicon/apple-touch-icon.png"/>
                </Link>
            </Navbar.Brand>
            <ul className="navbar-nav d-none d-sm-flex">
                <Nav.Link as={Link} to={"/"}>Home</Nav.Link>
                <Nav.Link as={Link} to={"/login"}>Login<span className="d-none d-md-inline"> / Sign Up</span></Nav.Link>
            </ul>
            <ul className="navbar-nav ml-auto flex-grow-1 flex-sm-grow-0">
                <div className={`navbar-search ${searchActive ? 'active' : ''}`}>
                    <span className="navbar-search-input-wrapper">
                        <input 
                            type="text" 
                            defaultValue={query}
                            onChange={ (e) => setQuery(e.target.value) }
                            className="form-control small"
                            placeholder="Enter keyword" />
                        <Link onClick={() => setSearchActive(false)} className="navbar-search-x">
                            <Icon icon={'ph:x-bold'} width={16} height={16} />
                        </Link>
                    </span>
                    <Link onClick={(e) => {
                            e.preventDefault()
                            if (!searchActive) setSearchActive(true)
                            else {
                                if (query.trim()) {
                                    navigate('/search?query='+query)
                                    // setQuery('')
                                    // setSearchActive(false)
                                }
                            }
                        }}
                        className="navbar-search-btn-wrapper">
                        <Icon icon={'material-symbols:search-rounded'} width={26} height={26} />
                    </Link>
                </div>
            </ul>
        </nav>
     );
}
 
export default FanNavbar;