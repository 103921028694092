import { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import '../assets/css/sb-admin-2.min.css';
import '../assets/css/styles.css';

import Login from "./Login";
import Register from "./Register";
import NotFound from "./NotFound";
import Home from "./Home";
import Search from "./Search";
import ArtistDetail from "./ArtistDetail";
import PosterDetail from "./PosterDetail";
import DonationHistory from "./DonationHistory";
import FavoriteArtists from "./FavoriteArtists";

import ArtistDashboard from "./Artists/Dashboard";
import ArtistDonationHistory from "./Artists/DonationHistory";
import ArtistPostersIndex from "./Artists/PostersIndex";
import ArtistPostersCreate from "./Artists/PostersCreate";
import ArtistPostersEdit from "./Artists/PostersEdit";
import ArtistProfileInfo from "./Artists/ProfileInfo";

import FanNavbar from "./FanNavbar";
import ArtistNavbar from "./ArtistNavbar";
import FanSidebar from "./FanSidebar";
import ArtistSidebar from "./ArtistSidebar";
import useUser from "../../useUser";
import Unauthorized from "./Unauthorized";
import ProfileInfo from "./ProfileInfo";
import DonePages from "./DonePages";
import Donate from "./Donate";

const App = () => {
    return ( 
        <Routes>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            
            <Route element={<AppLayout panel='fan' />} >
                <Route path="/" element={<Home />} />
                <Route path="/search" element={<Search />} />
                <Route path="/artist-details" element={<ArtistDetail />} />
                <Route path="/poster-details" element={<PosterDetail />} />
                <Route path="/donation-history" element={<DonationHistory />} />
                <Route path="/favorite-artists" element={<FavoriteArtists />} />
                <Route path="/personal-information" element={<ProfileInfo />} />
                <Route path="/donate" element={<Donate />} />
                
                <Route path="/pages" element={<DonePages />} />
                
                <Route path="unauthorized" element={<Unauthorized />} />

                <Route path="*" element={<NotFound />} />
            </Route>
            
            <Route element={<AppLayout panel='artist' />} >
                <Route path="/artists/dashboard" element={<ArtistDashboard />} />
                <Route path="/artists/donation-history" element={<ArtistDonationHistory />} />                
                <Route path="/artists/posters" element={<ArtistPostersIndex />} />
                <Route path="/artists/posters/create" element={<ArtistPostersCreate />} />
                <Route path="/artists/posters/edit" element={<ArtistPostersEdit />} />
                <Route path="/artists/personal-information" element={<ArtistProfileInfo />} />
                
                <Route path="unauthorized" element={<Unauthorized />} />

                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

const AppLayout = ({panel}) => {
    const { user, setUser, removeUser } = useUser();
    const [sidebarToggled, setSidebarToggled] = useState(false);

    return (
        <div className={`admin-app ${sidebarToggled ? 'app-sidebar-toggled' : ''}`} id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column" onClick={() => { if (sidebarToggled) setSidebarToggled(false) }}>
                <div id="content">
                    {panel == 'fan' ? (
                        <FanNavbar 
                            user={user} 
                            removeUser={removeUser}
                            sidebarToggled={sidebarToggled}
                            setSidebarToggled={setSidebarToggled}
                            />
                    ) : (
                        <ArtistNavbar 
                            user={user} 
                            removeUser={removeUser}
                            sidebarToggled={sidebarToggled}
                            setSidebarToggled={setSidebarToggled}
                            />
                    )}
                        
                    <div style={{ marginTop: '80px' }}>
                        <Outlet />
                    </div>
                </div>
            </div>
            {panel == 'fan' ? (
                <FanSidebar setSidebarToggled={setSidebarToggled} />
            ) : (
                <ArtistSidebar setSidebarToggled={setSidebarToggled} />
            )}
        </div>
    )
};
 
export default App;