import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import useFetch from '../../useFetch';

const ArtistSidebar = ({setSidebarToggled}) => {    
    const currentPath = useLocation().pathname.split('/')[2];
    
    const { response: pending } = useFetch(process.env.REACT_APP_BASE_API_URL+'/pending-count');

    const handleCloseSidebar = () => {
        setSidebarToggled(false)
    }
    
    return ( 
        <React.Fragment>
            <ul className="navbar-nav bg-white sidebar sidebar-dark accordion" id="accordionSidebar">
                <li className={`nav-item ${currentPath == 'dashboard' ? 'active' : ''}`}><Link to="/artists/dashboard" onClick={handleCloseSidebar} className="nav-link"><span>Dashboard</span></Link></li>
                <li className={`nav-item ${currentPath == 'posters' ? 'active' : ''}`}><Link to="/artists/posters" onClick={handleCloseSidebar} className="nav-link"><span>Manage Posters</span></Link></li>
                <li className={`nav-item ${currentPath == 'donation-history' ? 'active' : ''}`}><Link to="/artists/donation-history" onClick={handleCloseSidebar} className="nav-link"><span>Donation History</span></Link></li>
                <li className={`nav-item ${currentPath == 'exchange-history' ? 'active' : ''}`}><Link to="/artists/exchange-history" onClick={handleCloseSidebar} className="nav-link"><span>Exchange History</span></Link></li>
                <li className={`nav-item ${currentPath == 'register-information' ? 'active' : ''}`}><Link to="/artists/register-information" onClick={handleCloseSidebar} className="nav-link"><span>Register Information for Exchange</span></Link></li>
                <li className={`nav-item ${currentPath == 'personal-information' ? 'active' : ''}`}><Link to="/artists/personal-information" onClick={handleCloseSidebar} className="nav-link"><span>Manage Profile</span></Link></li>
                <li className={`nav-item mt-auto highlight`}><Link to="/" onClick={handleCloseSidebar} className="nav-link"><span>Move to the<br/>Fan Center</span></Link></li>
            </ul>
        </React.Fragment>
     );
}
 
export default ArtistSidebar;