import { TextField } from "@mui/material";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useUser from "../../useUser";

const Register = () => {
    let navigate = useNavigate();
    const { setUser } = useUser();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setUser({user: {name: 'Talha'}, access_token: 'token'});
        navigate('/');
        return
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("type", "email");
        formData.append("email", email);
        formData.append("password", password);

        fetch(process.env.REACT_APP_BASE_API_URL+'/login', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while logging in, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);
                setUser(data);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while logging in, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    return (
        <div className="container-fluid d-lg-flex" style={{ minHeight: '100vh' }}>
            <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-5 col-md-12 d-none d-lg-flex">
                    <div className="card o-hidden border-0 shadow my-3 flex-fill">
                        <div className="card-body px-0 pt-3 pb-1 d-flex flex-column justify-content-center h-100">
                            <div className="px-4 mb-4">
                                <Link to={"/"}>
                                    <img id="profile-img" className="profile-img-card" src="https://minimals.cc/favicon/apple-touch-icon.png" style={{width: '40px'}} />
                                </Link>
                                <h1 className="login-h1 mb-3 mt-3">Manage the job more effectively with Minimal</h1>
                            </div>
                            <img id="profile-img" className="profile-img-card" src="/illustration_register.png" style={{width: '100%'}} />
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-8 col-lg-7 col-md-12 d-flex">
                    <div className="row justify-content-center flex-fill">
                        <div className="col-xl-8 col-lg-10 col-md-9 col-sm-11">
                            <div className="card-body p-3 d-flex flex-column justify-content-center h-100">
                                <Link to={"/"} className="mt-3 mb-3 d-lg-none">
                                    <img id="profile-img" className="profile-img-card" src="https://minimals.cc/favicon/apple-touch-icon.png" style={{width: '40px'}} />
                                </Link>
                                <h4 className="login-h4 mb-3">Create a new account</h4>

                                { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                                { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                                { validationErrors.length > 0 &&
                                    <div className="alert-msg-danger">
                                        <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                        <ul className="mb-0" style={{fontSize: '15px'}}>
                                            { validationErrors.map((validationError, index) => (
                                                <li key={index}>{ validationError }</li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                        
                                <form className="user" onSubmit={handleSubmit}>
                                    <TextField 
                                        variant="outlined" 
                                        label="Full name" 
                                        value={name}
                                        onChange={ (e) => setName(e.target.value) }
                                        required={true}
                                        style={{ width: '100%' }}
                                        className="mb-3"
                                        />

                                    <TextField 
                                        variant="outlined" 
                                        label="Email address" 
                                        value={email}
                                        onChange={ (e) => setEmail(e.target.value) }
                                        required={true}
                                        style={{ width: '100%' }}
                                        className="mb-3"
                                        />
                                        
                                    <TextField 
                                        variant="outlined" 
                                        type={"password"}
                                        label="Password" 
                                        value={password}
                                        onChange={ (e) => setPassword(e.target.value) }
                                        required={true}
                                        style={{ width: '100%' }}
                                        className="mb-3"
                                        />

                                    <button 
                                        type="submit" 
                                        className="btn btn-secondary btn-user btn-block"
                                        disabled={isLoading}
                                        >{ isLoading ? 'Loading...' : 'Register' }</button>

                                    <p class="register-agree mt-3">
                                        By registering, I agree to Minimal&nbsp;
                                        <a href="#">Terms of Service</a>&nbsp;and&nbsp;
                                        <a href="#">Privacy Policy</a>.
                                    </p>

                                    <p class="login-cta mt-3">
                                        Already have an account?&nbsp;
                                        <Link to={"/login"}>Login</Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Register;