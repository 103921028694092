import { useState } from 'react';
import { constants } from './Constants';
import ArtistCard from './ArtistCard';
import { Icon } from '@iconify/react';
import PosterCard from './PosterCard';
import { Button } from 'react-bootstrap';

const ArtistDetail = () => {
    document.title = "Artist Detail | Fan Website";

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className='text-center mb-3'>
                <img src="https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_1.jpg" className='avatar' />

                <h6 className="title">
                    Lucian Obrien
                </h6>

                <div className="d-flex justify-content-center mt-1 mb-2">
                    <div className="px-4 border-right">
                        <p className="info-title">Followers</p>
                        <p className="info-count">91.56k</p>
                    </div>
                    <div className="px-4">
                        <p className="info-title">Posts</p>
                        <p className="info-count">3.41k</p>
                    </div>
                </div>

                <Button variant='tab' className="mt-1 mb-3">
                    <Icon icon="uim:star" width={20} height={20} />Add to Favorite
                </Button>

                <div className='row justify-content-center'>
                    <div className='col-xl-8 col-lg-9 col-md-10'>
                        <p className="info px-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </div>
            </div>

            <h4 className="heading1 mb-3 text-center">Uploaded Posters</h4>
            <div className='row row-cols-card justify-content-center'>
                {constants.posters.slice(0, Math.max(1,Math.round(Math.random()*10))).map((item) => (
                    <PosterCard poster={item} className={'col mb-4'} />
                ))}
            </div>
        </div>
    );
}
 
export default ArtistDetail;