import { useRef, useState } from 'react';
import { constants } from './Constants';
import ArtistCard from './ArtistCard';
import { Icon } from '@iconify/react';
import PosterCard from './PosterCard';
import { Button, Card, Image } from 'react-bootstrap';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

const images = [
    "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_1.jpg",
    "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_2.jpg",
    "https://minimal-assets-api-dev.vercel.app/assets/images/covers/cover_3.jpg"
]
  
const Donate = () => {
    document.title = "Donate | Fan Website";

    const slider1 = useRef(null);

    const [selectedPackage, setSelectedPackage] = useState('spoon');
    const [quantity, setQuantity] = useState(1);
    const [selectedImage, setSelectedImage] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [tab, setTab] = useState(0);
    
    return (
        <>
            <div className="container-fluid poster-details donate-card mb-fixed-footer mb-md-4 pt-2">
                <div className="row justify-content-center flex-fill">
                    <div className="col-card col-sm-10">
                        <div className='details-card px-3 pb-3'>
                            <h6 className="title text-center mb-3">Gift your hearts</h6>
                            
                            <div className='row justify-content-center'>
                                <div className='col-12 col-poster mb-4 mb-sm-0'>
                                    <h4 className="heading1 mb-2 text-center text-sm-left">Selected Poster</h4>
                                    <PosterCard poster={constants.posters[0]} className={''} />
                                </div>
                            
                                <div className='col-12 col-package'>
                                    <h4 className="heading1 mb-2">Select hearts</h4>
                                    <div className={`donate-package-item ${selectedPackage == 'spoon' ? 'active' : ''}`} onClick={() => setSelectedPackage('spoon')}>
                                        <span className='icon'>
                                            <Icon icon="fa6-solid:spoon" width={16} height={16} />
                                        </span>
                                        <span className='title'>A spoon of hearts</span>
                                        <span className='price'>$3000</span>
                                    </div>
                                    <div className={`donate-package-item ${selectedPackage == 'cup' ? 'active' : ''}`} onClick={() => setSelectedPackage('cup')}>
                                        <span className='icon'>
                                            <Icon icon="bi:cup-fill" width={16} height={16} />
                                        </span>
                                        <span className='title'>A cup of hearts</span>
                                        <span className='price'>$5000</span>
                                    </div>
                                    <div className={`donate-package-item ${selectedPackage == 'box' ? 'active' : ''}`} onClick={() => setSelectedPackage('box')}>
                                        <span className='icon'>
                                            <Icon icon="raphael:package" width={16} height={16} />
                                        </span>
                                        <span className='title'>A box of hearts</span>
                                        <span className='price'>$10000</span>
                                    </div>

                                    <div className='quantity-container mt-4 mb-3'>
                                        <h4 className="heading1">Quantity</h4>
                                        <div className='d-flex'>
                                            <Link className='quantity-btn' onClick={(e) => {
                                                e.preventDefault()
                                                setQuantity(quantity => {
                                                    if (quantity == 1) return 1
                                                    return quantity-1
                                                })
                                            }}>
                                                <Icon icon={'ph:minus-bold'} height={16} width={16} />
                                            </Link>
                                            <p className='count'>{quantity}</p>
                                            <Link className='quantity-btn' onClick={(e) => {
                                                e.preventDefault()
                                                setQuantity(quantity => {
                                                    return quantity+1
                                                })
                                            }}>
                                                <Icon icon={'ph:plus-bold'} height={16} width={16} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Button variant='tab' className="mx-0 mt-1">
                                        <Icon icon="bi:credit-card" width={20} height={20} />Pay ${quantity * (selectedPackage == 'spoon' ? 3000 : (selectedPackage == 'cup' ? 5000 : 10000))}
                                    </Button>

                                    <div className='mt-2'>
                                        <Link className='text-danger small'>
                                            Button for failure payment
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            {/* <p className="info d-none d-md-block d-lg-none mt-3"><span><Icon icon="bi:info-circle" width={16} height={16} /></span><b>Description:</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Donate;