import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const PosterCard = ({poster, setDeleteItem}) => {
    return (
        <div className={'col mb-4'}>
            <div className='poster-card'>
                <div className="poster">
                    <Link to={'/poster-details'}>
                        <span>
                            <img src={poster.images[0]} />
                        </span>
                    </Link>
                </div>
                <div className="px-3">
                    <Link to={'/poster-details'}>
                        <h6 className="title">
                            {poster.name}
                        </h6>
                        <p className="info">
                            12 Jan 2022 | Venue Hall
                        </p>
                    </Link>
                    
                    <div className="d-flex justify-content-between mb-3">
                        <Button as={Link} to="/artists/posters/edit" size="sm" className="flex-fill mr-1">
                            Edit
                        </Button>
                        
                        <Button onClick={() => setDeleteItem({id: poster.id, url: 'posters/'+poster.id})} size="sm" variant="danger" className="flex-fill ml-1">
                            Delete
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PosterCard;