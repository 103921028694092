import { useState } from 'react';
import { constants } from './Constants';
import ArtistCard from './ArtistCard';
import { Icon } from '@iconify/react';
import PosterCard from './PosterCard';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FavoriteArtists = () => {
    document.title = "Favorite Artists | Fan Website";

    return (
        <div className="container-fluid artist-details mb-4 pt-2">
            <div className='general-card mb-4'>
                <div className='general-card-header'>
                    <h4 className="heading1 mb-0">My Favorite Artists</h4>
                </div>
                {constants.artists.slice(0, Math.max(2,Math.round(Math.random()*10))).map((artist) => (
                    <Link to={'/artist-details'} style={{ textDecoration: 'none' }}>
                        <div className='artist-item'>
                            <img src={artist.cover} className="avatar" />
                            <div className="d-flex flex-column">
                                <p className="artist-title">{artist.name}</p>
                                <p className="artist-info">3.41k followers</p>
                            </div>
                            <div className='ml-auto'>
                                <button className="btn-star">
                                    <Icon icon="uim:star" width={20} height={20} />
                                </button>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}
 
export default FavoriteArtists;